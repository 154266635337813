import React, { lazy, Suspense } from 'react';

import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const FinancialReports = lazy(() => import('src/components/financial-reports/financial-reports'));

const isBrowser = typeof window !== 'undefined';

const FinancialReportsPage = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Financial Reports" />
      {isBrowser && (
        <Suspense fallback={<div />}>
          <FinancialReports />
        </Suspense>
      )}
    </Layout>
  );
};

export default FinancialReportsPage;
